<template>
  <div class="garden-detail-page" v-show="pageLoading">
    <div class="form-wrapper">
      <a-form-model :model="form" ref="parkForm" :rules="parkRules" :label-col="labelCol" :wrapper-col="wrapperCol">
        <a-row>
          <a-col :span="8">
            <a-form-model-item ref="park_type_id" label="类型" prop="park_type_id">
              <a-select class="a-select" placeholder="请选择" v-model="form.park_type_id">
                <a-select-option v-for="(item, index) in typeList" :key="item.park_type_id">{{ item.park_type_name }}</a-select-option>
              </a-select>
            </a-form-model-item>
          </a-col>
          <a-col :span="8">
            <a-form-model-item ref="park_name" label="名称" prop="park_name">
              <a-input v-model="form.park_name" placeholder="请输入名称" />
            </a-form-model-item>
          </a-col>
          <a-col :span="8">
            <a-form-model-item ref="contact" label="联系电话" prop="contact">
              <a-input v-model="form.contact" placeholder="请输入联系方式" />
            </a-form-model-item>
          </a-col>
          <a-col :span="8">
            <a-form-model-item ref="operator" label="运营商" prop="operator">
              <a-input v-model="form.operator" placeholder="请输入运营商" />
            </a-form-model-item>
          </a-col>
          <a-col :span="8">
            <a-form-model-item ref="addr" label="行政地区">
              <a-cascader
                v-model="form.addrValue"
                :default-value="form.ssqvalue"
                class="cascader"
                :field-names="{
                  label: 'name',
                  value: 'name',
                  children: 'districts',
                }"
                :load-data="loadSsq"
                change-on-select
                :options="ssqData"
                placeholder="请选择省市区"
                @change="ssqChange"
              />
            </a-form-model-item>
          </a-col>
          <a-col :span="8">
            <a-form-model-item ref="address" label="项目地址" prop="address">
              <a-input v-model="form.address" placeholder="请输入项目地址" />
            </a-form-model-item>
          </a-col>
          <a-col :span="8">
            <a-form-model-item ref="price_id" label="租金单价" prop="price_id">
              <a-select @change="priceChange" v-model="form.price_id" class="a-select" placeholder="请选择租金">
                <a-select-option :value="item.id" v-for="(item, index) in priceList" :key="item.id">{{ item.label }}</a-select-option>
              </a-select>
            </a-form-model-item>
          </a-col>
          <a-col :span="8">
            <a-form-model-item ref="area_id" label="面积" prop="area_id">
              <a-select @change="areaChange" v-model="form.area_id" class="a-select" placeholder="请选择面积">
                <a-select-option :value="item.id" v-for="(item, index) in areaList" :key="item.id">{{ item.label }}</a-select-option>
              </a-select>
            </a-form-model-item>
          </a-col>
        </a-row>
        <a-form-model-item :labelCol="{ span: 2 }" :wrapperCol="{ span: 22 }" ref="policy_tag_list" label="相关政策" prop="policy_tag_list">
          <a-select v-model="form.policy_tag_list" mode="multiple" style="width: 100%" placeholder="请选择相关政策" :showArrow="true">
            <a-select-option :value="item.policy_tag_id" v-for="(item, index) in policyTypeList" :key="item.policy_tag_id">{{ item.policy_tag_name }}</a-select-option>
          </a-select>
        </a-form-model-item>
        <a-form-model-item :labelCol="{ span: 2 }" :wrapperCol="{ span: 22 }" ref="matcheing_set_tag_list" label="配套设施" prop="matcheing_set_tag_list">
          <a-select v-model="form.matcheing_set_tag_list" mode="multiple" style="width: 100%" placeholder="请选择配套" :showArrow="true">
            <a-select-option :value="item.matching_set_name" v-for="(item, index) in setList" :key="item.matching_set_id">{{ item.matching_set_name }}</a-select-option>
          </a-select>
        </a-form-model-item>
        <a-form-model-item :labelCol="{ span: 2 }" :wrapperCol="{ span: 22 }" ref="park_tag_list" label="标签" prop="park_tag_list">
          <a-select v-model="form.park_tag_list" @change="parkTagChange" mode="multiple" style="width: 100%" placeholder="请选择标签" :showArrow="true">
            <a-select-option v-show="!item.cut" :value="item.park_tag_id" v-for="(item, index) in parkTagList" :key="item.park_tag_id">{{ item.park_tag_name }}</a-select-option>
          </a-select>
        </a-form-model-item>
        <a-form-model-item :labelCol="{ span: 2 }" :wrapperCol="{ span: 22 }" ref="header_img" label="园区展示图" prop="header_img">
          <a-upload :action="$domain + '/api/resource/uploadImg'" list-type="picture-card" @change="handleHead" :show-upload-list="false">
            <img class="place-img" :src="$imglink + form.header_img" v-if="form.header_img" />
            <div v-else>
              <a-icon type="plus" />
              <div class="ant-upload-text">上传图片</div>
            </div>
          </a-upload>
        </a-form-model-item>
        <a-form-model-item :labelCol="{ span: 2 }" :wrapperCol="{ span: 22 }" ref="exhibition_img_list" label="园区图片" prop="exhibition_img_list">
          <a-upload :action="$domain + '/api/resource/uploadImg'" list-type="picture-card" :fileList="fileList" @change="handleChange" @remove="removeChange" @preview="showPrevImg">
            <div v-if="fileList.length < 6">
              <a-icon type="plus" />
              <div class="ant-upload-text">上传图片</div>
            </div>
          </a-upload>
        </a-form-model-item>
        <a-form-model-item :labelCol="{ span: 2 }" :wrapperCol="{ span: 22 }" ref="exhibition_img_list" label="园区视频" prop="exhibition_video_list">
          <a-upload :action="$domain + '/api/resource/uploadVideo'" list-type="picture-card" :fileList="vfileList" @change="handleChange2" @remove="removeChange2" @preview="showPrevImg2">
            <div v-if="vfileList.length < 3">
              <a-icon type="plus" />
              <div class="ant-upload-text">上传视频</div>
            </div>
          </a-upload>
        </a-form-model-item>

        <a-form-model-item :labelCol="{ span: 2 }" :wrapperCol="{ span: 22 }" ref="park_introduce" label="园区简介" prop="park_introduce">
          <div id="editor1-toolbar-container"></div>
          <div id="editor1"></div>
        </a-form-model-item>
        <a-form-model-item :labelCol="{ span: 2 }" :wrapperCol="{ span: 22 }" ref="park_policy" label="园区政策" prop="park_policy">
          <div id="editor2-toolbar-container"></div>
          <div id="editor2"></div>
        </a-form-model-item>
      </a-form-model>
      <div class="sub-form-btn-box">
        <a-button :loading="subing" type="primary" @click="subForm">提交</a-button>
      </div>
    </div>
    <a-modal class="previewImgModal" :visible="previewVisible" :footer="null" @cancel="handleCancel">
      <img alt="example" style="width: 100%" :src="previewImage" />
    </a-modal>
    <a-modal class="previewImgModal" :visible="previewVisible2" :footer="null" @cancel="handleCancel">
      <video :src="previewVideo" controls></video>
    </a-modal>

    <div class="loading-mask" v-show="subing">
      <a-spin>
        <a-icon slot="indicator" type="loading" style="font-size: 24px" spin />
      </a-spin>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import { ssqdata } from "../../utils/ssqdata.js";
import CKEditor from '@ckeditor/ckeditor5-build-decoupled-document'
import '@ckeditor/ckeditor5-build-decoupled-document/build/translations/zh-cn'
import MyUploadAdapter from "../../utils/uploader";
import { toolbar, fontSize } from '../../utils/toolbar'
export default {
  data() {
    let priceList = [
      {
        id: 1,
        low_price: 0,
        high_price: 1,
        label: "1元以下/(m².天)",
      },
      {
        id: 2,
        low_price: 1,
        high_price: 1.5,
        label: "1-1.5元/(m².天)",
      },
      {
        id: 3,
        low_price: 1.5,
        high_price: 2,
        label: "1.5-2元/(m².天)",
      },
      {
        id: 4,
        low_price: 2,
        high_price: 2.5,
        label: "2-2.5元/(m².天)",
      },
      {
        id: 5,
        low_price: 2.5,
        high_price: 3,
        label: "2.5-3元/(m².天)",
      },
      {
        id: 6,
        low_price: 3,
        high_price: 3.5,
        label: "3-3.5元/(m².天)",
      },
      {
        id: 7,
        low_price: 3.5,
        high_price: 4,
        label: "3.5-4元/(m².天)",
      },
      {
        id: 8,
        low_price: 4,
        high_price: 9999999,
        label: "4元以上/(m².天)",
      },
    ];
    let areaList = [
      {
        id: 1,
        low_area: 0,
        high_area: 50,
        label: "50㎡以下",
      },
      {
        id: 2,
        low_area: 50,
        high_area: 100,
        label: "50-100㎡",
      },
      {
        id: 3,
        low_area: 100,
        high_area: 150,
        label: "100-150㎡",
      },
      {
        id: 4,
        low_area: 150,
        high_area: 200,
        label: "150-200㎡",
      },
      {
        id: 5,
        low_area: 200,
        high_area: 300,
        label: "200-300㎡",
      },
      {
        id: 6,
        low_area: 300,
        high_area: 500,
        label: "300-500㎡",
      },
      {
        id: 7,
        low_price: 500,
        high_price: 1000,
        label: "500-1000㎡",
      },
      {
        id: 8,
        low_area: 1000,
        high_area: 9999999,
        label: "1000㎡以上",
      },
    ];
    let addrValidator = (rule, value, callback) => {
      console.log("地区验证：", value);
      if (value.length <= 0) {
        callback(new Error("请补全地域信息"));
      } else {
        callback();
      }
    };
    return {
      editor1: null,
      editor2: null,
      editor1Data: '',
      editor2Data: '',
      pageLoading: false,
      labelCol: { span: 6 },
      wrapperCol: { span: 18 },
      priceList,
      areaList,
      setList: [],
      typeList: [],
      policyTypeList: [],
      parkTagList: [],
      previewVisible: false,
      previewVisible2: false,
      previewImage: "",
      previewVideo: "",
      quill: {},
      addrValue: [],
      fileList: [],
      vfileList: [],
      form: {
        addrValue: [],
        park_type_id: undefined,
        park_name: "",
        contact: "",
        operator: "",
        pro: "",
        pro_code: "",
        city: "",
        city_code: "",
        area: "",
        area_code: "",
        address: "",
        lng: "",
        lat: "",
        price_id: undefined,
        low_price: "",
        high_price: "",
        area_id: undefined,
        low_area: "",
        high_area: "",
        policy_tag_list: [],
        matcheing_set_tag_list: [],
        park_tag_list: [],
        header_img: "",
        exhibition_img_list: "",
        park_introduce: "",
        park_policy: "",
        fileList: [],
      },
      ssqData: ssqdata,
      allSsqData: ssqdata,
      parkForm: this.$form.createForm(this, { name: "parkForm" }),
      parkRules: {
        park_type_id: [
          { required: true, message: "请选择园区类型", trigger: "change" },
        ],
        park_name: [
          { required: true, message: "请填写园区名字", trigger: "blur" },
        ],
        contact: [
          { required: true, message: "请填写联系方式", trigger: "blur" },
        ],
        operator: [
          { required: true, message: "请填写运营商", trigger: "blur" },
        ],
        address: [
          { required: true, message: "请填写详细地址", trigger: "blur" },
        ],
        price_id: [
          { required: true, message: "请选择价格区间", trigger: "blur" },
        ],
        area_id: [
          { required: true, message: "请选择面积区间", trigger: "blur" },
        ],
        policy_tag_list: [
          { required: true, message: "请选择相关政策", trigger: "change" },
        ],
        matcheing_set_tag_list: [
          { required: true, message: "请选择配套设施", trigger: "change" },
        ],
        park_tag_list: [
          { required: true, message: "请选择园区标签", trigger: "change" },
        ],
        header_img: [
          { required: true, message: "请选择园区展示图片", trigger: "change" },
        ],
        exhibition_img_list: [
          { required: true, message: "请选择园区图片", trigger: "change" },
        ],
        park_introduce: [
          { required: true, message: "请输入园区简介", trigger: "blur" },
        ],
        park_policy: [
          { required: true, message: "请输入政策简介", trigger: "blur" },
        ],
      },
      subing: false,
      park_id: "",
      old_park: false,
      addrCode: [],
      curParent: [],
    };
  },
  computed: {
    ssqvalue() {
      return [this.pro, this.city, this.area];
    },
  },
  watch: {
    editor1Data(val) {
      this.form.park_introduce = val
    },
    editor2Data(val) {
      this.form.park_policy = val
    }
  },
  async created() {
    if (this.$route.query.id) {
      this.park_id = this.$route.query.id;
      const data = await this.getParkDetail();
      if (data.code == 0) {
        this.old_park = data.data.park_tag_list;
        this.form = data.data;
        this.form.park_tag_list = [];
        this.form.price_id = parseFloat(data.data.price_id);
        this.form.area_id = parseFloat(data.data.area_id);
        delete this.form.is_show;
        delete this.form.is_recommend;
        console.log(typeof this.form.pro_code);
        this.addrCode = [
          this.form.pro_code,
          this.form.city_code,
          this.form.area_code,
        ];
        this.form.addrValue = [this.form.pro, this.form.city, this.form.area];
        console.log(this.form.addrValue);
        let li = 1;
        this.fileList = this.form.exhibition_img_list.map((item) => {
          let a = {
            uid: -li,
            name: "image.png",
            status: "done",
            url: this.$imglink + item,
            response: {
              data: {
                resource_id: item,
                url: this.$imglink + item,
              },
            },
          };
          li++;
          return a;
        });
        let li2 = 1;
        this.vfileList = this.form.exhibition_video_list.map((item) => {
          let a = {
            uid: -li2,
            name: "video.mp4",
            status: "done",
            url: this.$imglink + item + "&res_type=video_s",
            response: {
              data: {
                resource_id: item,
                url: this.$imglink + item + "&res_type=video_s",
              },
            },
          };
          li2++;
          return a;
        });
      } else {
        this.$message.error(data.msg, 1.5);
      }

      this.curParent = ssqdata;
      this.initLoad(0);
      console.log(JSON.stringify(this.form));
    }

    this.getMatchingSetList();
    this.getParkTypeList();
    this.getPolicyTypeList();
    this.getParkTagList();
  },
  mounted() {
    let t = this;
    t.initCKEditor('editor1')
    t.initCKEditor('editor2')

    setTimeout(() => {
      t.pageLoading = true;
      this.editor1.setData(t.form.park_introduce)
      this.editor2.setData(t.form.park_policy)
    }, 400);
  },
  methods: {
    myUploadImagePlugin(ele) {
      let t = this
      t[ele].plugins.get("FileRepository").createUploadAdapter = loader => {
        return new MyUploadAdapter(loader);
      };
      t[ele].model.document.on('change:data', function () {
        t[ele + 'Data'] = t[ele].getData()
      });
    },
    initCKEditor(ele) {
      let t = this
      CKEditor.create(document.querySelector('#' + ele), {
        toolbar: toolbar,
        fontSize: fontSize,
        language: 'zh-cn',
        ckfinder: {
          uploadUrl: '/admin/Upload/uploadUrl'
          //后端处理上传逻辑返回json数据,包括uploaded(选项true/false)和url两个字段
        },
      }).then(editor => {
        const toolbarContainer = document.querySelector('#' + ele + '-toolbar-container');
        console.log(toolbarContainer)
        toolbarContainer.appendChild(editor.ui.view.toolbar.element);
        this[ele] = editor //将编辑器保存起来，用来随时获取编辑器中的内容等，执行一些操作
        t.myUploadImagePlugin(ele)
        console.log(Array.from(this[ele].ui.componentFactory.names()))
      }).catch(error => {
        console.error(error);
      });
    },
    getParkDetail() {
      let t = this;
      return t.$post("parkInfo", { park_id: t.park_id });
      // .then(res => {
      // 	let { code, data, msg } = res
      // 	if (code == 0) {
      // 		t.form = data
      // 		delete t.form.is_show
      // 		delete t.form.is_recommend

      // 	} else {
      // 		t.$message.error(msg, 1.5)
      // 	}
      // }).catch(err => {

      // })
    },
    getParkTypeList() {
      let t = this;
      t.$post("ParkTypeList", { limit: 9999 }).then((res) => {
        let { code, data, msg } = res;
        if (code == 0) {
          t.typeList = data.list;
        }
      });
    },
    getMatchingSetList() {
      let t = this;
      t.$post("MatchingSetList", { limit: 9999 })
        .then((res) => {
          let { code, data, msg } = res;
          if (code == 0) {
            t.setList = data.list;
          } else {
            t.$message.error(msg, 1.5);
          }
        })
        .catch((err) => { });
    },
    getPolicyTypeList() {
      let t = this;
      t.$post("PolicyTagList", { limit: 9999 })
        .then((res) => {
          let { code, data, msg } = res;
          if (code == 0) {
            t.policyTypeList = data.list;
          } else {
            t.$message.error(msg, 1.5);
          }
        })
        .catch((err) => { });
    },
    getParkTagList() {
      let t = this;
      t.$post("parkTagList", { limit: 9999 })
        .then((res) => {
          let { code, data, msg } = res;
          if (code == 0) {
            let old_park = [];
            if (t.old_park) {
              old_park = JSON.parse(t.old_park);
            }
            for (let i = 0; i < old_park.length; i++) {
              // 默认没有删掉
              old_park[i].cut = false;
            }
            for (let k = 0; k < data.list.length; k++) {
              data.list[k].cut = false;
            }
            for (let i = 0; i < old_park.length; i++) {
              let item = old_park[i];
              let had = false;
              for (let k = 0; k < data.list.length; k++) {
                if (data.list[k].park_tag_name == item.park_tag_name) {
                  had = true;
                  break;
                }
              }
              if (!had) {
                // 如果对比后发现没有，则设为已被删除
                item.cut = true;
                data.list.push(item);
              }
            }
            t.form.park_tag_list = old_park.map((item) => {
              item = item.park_tag_id;
              return item;
            });
            t.parkTagList = data.list;

            console.log("biaoqian", t.form.park_tag_list);
            console.log("parkTagList", t.parkTagList);
          } else {
            t.$message.error(msg, 1.5);
          }
        })
        .catch((err) => { });
    },
    priceChange(e) {
      console.log(typeof this.form.price_id);
      for (let i = 0; i < this.priceList.length; i++) {
        if (e == this.priceList[i].id) {
          this.form.low_price = this.priceList[i].low_price;
          this.form.high_price = this.priceList[i].high_price;
          break;
        }
      }
    },
    areaChange(e) {
      for (let i = 0; i < this.areaList.length; i++) {
        if (e == this.areaList[i].id) {
          this.form.low_area = this.areaList[i].low_area;
          this.form.high_area = this.areaList[i].high_area;
          break;
        }
      }
    },
    handleHead(res) {
      console.log(res);
      if (res.file.status == "done") {
        this.form.header_img = res.file.response.data.resource_id;
        console.log(this.form.header_img);
      }
    },
    parkTagChange(e) { },
    removeChange(e) {
      console.log(e);
      console.log(e);
    },
    removeChange2(e) {
      console.log(e);
      console.log(e);
    },
    handleChange(res) {
      console.log(res);
      // exhibition_img_list
      this.fileList = res.fileList;
      if (res.file.status == "done" || res.file.status == "removed") {
        let imgArr = res.fileList.map((item) => {
          return item.response.data.resource_id;
        });
        this.form.exhibition_img_list = imgArr;

        console.log(this.form.exhibition_img_list);
      }
    },
    handleChange2(res) {
      console.log(res);
      // exhibition_img_list
      this.vfileList = res.fileList.map((item) => {
        item.thumbUrl =
          item.response && item.response.code == 0
            ? item.response.data.url + item.response.data.res_type_str
            : "";
        return item;
      });
      console.log(res.fileList);
      if (res.file.status == "done" || res.file.status == "removed") {
        let imgArr = res.fileList.map((item) => {
          return item.response.data.resource_id;
        });

        this.form.exhibition_video_list = imgArr;
        console.log(this.form.exhibition_video_list);
      }
    },
    showPrevImg(e) {
      console.log(e);
      this.previewVisible = true;
      this.previewImage = e.response.data.url;
    },
    showPrevImg2(e) {
      this.previewVisible2 = true;
      this.previewVideo = e.response.data.url;
    },
    handleCancel() {
      this.previewImage = "";
      this.previewVideo = "";
      this.previewVisible = false;
      this.previewVisible2 = false;
    },
    initLoad(ind) {
      console.log(13123123131231231231231);
      let code = this.addrCode[ind];
      for (let i = 0; i < this.curParent.length; i++) {
        let item = this.curParent[i];
        if (code == item.adcode) {
          const targetOption = item;
          targetOption.loading = true;
          let subdistrict =
            targetOption.level == "province"
              ? 1
              : targetOption.level == "city"
                ? 2
                : targetOption.level == "district"
                  ? 3
                  : 4;
          axios
            .get("https://restapi.amap.com/v3/config/district", {
              params: {
                keywords: targetOption.adcode,
                subdistrict: subdistrict,
                key: "ce7404ac71c28a7893cae3ccc5517da0",
              },
            })
            .then((res) => {
              for (let i = 0; i < res.data.districts[0].districts.length; i++) {
                let item = res.data.districts[0].districts[i];
                delete item.districts;
                item.isLeaf =
                  item.level == "district" ||
                  res.data.districts[0].adcode == 810000 ||
                  res.data.districts[0].adcode == 820000;
              }
              targetOption.districts = res.data.districts[0].districts;
              this.ssqData = [...this.ssqData];
              targetOption.loading = false;
              this.curParent = res.data.districts[0].districts;
              console.log(ind + 2);
              if (ind + 2 != this.addrCode.length) {
                this.initLoad(ind + 1);
              }
            })
            .catch((err) => {
              console.log(err);
            });
          break;
        }
      }
    },
    loadSsq(selectedOptions) {
      console.log(selectedOptions);
      const targetOption = selectedOptions[selectedOptions.length - 1];
      targetOption.loading = true;
      // load options lazily
      let subdistrict =
        targetOption.level == "province"
          ? 1
          : targetOption.level == "city"
            ? 2
            : targetOption.level == "district"
              ? 3
              : 4;
      axios
        .get("https://restapi.amap.com/v3/config/district", {
          params: {
            keywords: targetOption.adcode,
            subdistrict: subdistrict,
            key: "ce7404ac71c28a7893cae3ccc5517da0",
          },
        })
        .then((res) => {
          let districts = res.data.districts[0].districts;
          for (let i = 0; i < districts.length; i++) {
            let item = districts[i];
            delete item.districts;
            item.isLeaf =
              item.level == "district" ||
              res.data.districts[0].adcode == 810000 ||
              res.data.districts[0].adcode == 820000;
          }
          targetOption.districts = districts;
          targetOption.loading = false;
          this.ssqData = [...this.ssqData];
        })
        .catch((err) => {
          console.log(err);
        });
    },
    ssqChange(e, opt) {
      this.form.pro = e[0] || "";
      this.form.city = e[1] || "";
      this.form.area = e[2] || "";
      this.form.pro_code = opt && opt[0] ? opt[0].adcode : "";
      this.form.city_code = opt && opt[1] ? opt[1].adcode : "";
      this.form.area_code = opt && opt[2] ? opt[2].adcode : "";
    },
    async subForm() {
      let t = this;
      t.$refs.parkForm.validate(async (valid) => {
        if (valid) {
          t.subing = true;
          let data = JSON.parse(JSON.stringify(t.form));
          const positonInfo = await t.getPosition();
          if (positonInfo.data.status == 1) {
            let arr = positonInfo.data.geocodes[0].location.split(",");
            data.lng = parseFloat(arr[0]);
            data.lat = parseFloat(arr[1]);
          }
          let park_tag_list = [];
          for (let i = 0; i < t.form.park_tag_list.length; i++) {
            for (let k = 0; k < t.parkTagList.length; k++) {
              if (t.form.park_tag_list[i] == t.parkTagList[k].park_tag_id) {
                park_tag_list.push(t.parkTagList[k]);
                break;
              }
            }
          }

          data.park_tag_list = JSON.stringify(park_tag_list);

          let url = "",
            msg = "";
          if (this.park_id) {
            url = "parkEdit";
            msg = "修改";
          } else {
            url = "parkAdd";
            msg = "提交";
          }

          t.$post(url, data)
            .then((res) => {
              let { code, data, msg } = res;
              if (code == 0) {
                t.$message.success(msg + "成功", 1.5);
              } else {
                t.$message.error(msg, 1.5);
              }
              t.subing = false;
              this.$router.go(-1);
            })
            .catch((err) => {
              t.subing = false;
            });
        }
      });
    },
    getPosition() {
      let t = this;
      return new Promise((resolve, reject) => {
        axios({
          url: "https://restapi.amap.com/v3/geocode/geo?parameters",
          params: {
            key: "af09a585ac836682dabaf9c3a8f818fd",
            address: t.form.pro + t.form.city + t.form.area + t.form.address,
          },
        })
          .then((res) => {
            resolve(res);
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
  },
};
</script>

<style lang="less"></style>
